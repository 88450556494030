@use 'variables';

.p-radiobutton {
  width: 1.5rem;
  height: 1.5rem;

  .p-radiobutton-box {
    border: 1px solid var(--primary);
    background: transparent;
    width: 1.5rem;
    height: 1.5rem;
    color: #212529;
    border-radius: 50%;
    transition:
      background-color 0.3s,
      border-color 0.3s,
      box-shadow 0.3s;

    &:not(.p-disabled) {
      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        border-color: white;
      }

      &:not(.p-highlight):hover {
        border-color: var(--primary);
      }
    }

    &.p-highlight {
      border-color: var(--primary);
      background-color: var(--color-primary-50);
    }
  }

  &.p-radiobutton-checked .p-radiobutton-icon {
    width: 1rem;
    height: 1rem;
    transition-duration: 0.15s;
    background-color: var(--primary);
  }
}

.p-radiobutton-label {
  font-weight: 800;
  margin-left: 0.5rem;

  &.p-radiobutton-label-active {
    color: var(--primary);
  }
}
