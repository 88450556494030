$font-size-10: 0.625rem;
$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-26: 1.625rem;
$font-size-28: 1.75rem;
$font-size-30: 1.875rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;

// ALIGNMENT
.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

// FONT
.bold {
  font-weight: bold;
}

.bold-300 {
  font-weight: 300;
}

.bold-400 {
  font-weight: 400;
}

.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}

.bold-800 {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.no-wrap {
  text-wrap: nowrap;
}

// Text pixel sizes
.text-10 {
  font-size: $font-size-10;
  line-height: 110%;
  letter-spacing: 0.0625rem;
}

.text-12 {
  font-size: $font-size-12;
  line-height: 150%;
}

.text-14 {
  font-size: $font-size-14;
  line-height: 130%;
}

.text-16 {
  font-size: $font-size-16;
}

.text-18 {
  font-size: $font-size-18;
}

.text-20 {
  font-size: $font-size-20;
  letter-spacing: -0.03938rem;
}

.text-22 {
  font-size: $font-size-22;
  letter-spacing: -0.03938rem;
}

.text-24 {
  font-size: $font-size-24;
  letter-spacing: -0.045rem;
}

.text-26 {
  font-size: $font-size-26;
}

.text-28 {
  font-size: $font-size-28;
}

.text-30 {
  font-size: $font-size-30;
}

.text-32 {
  font-size: $font-size-32;
  line-height: 110%;
}

.text-36 {
  font-size: $font-size-36;
  line-height: 110%;
}

// LETTER SPACING
.letter-spacing-normal {
  letter-spacing: normal;
}

// LINE HEIGHT
.line-height-130 {
  line-height: 130%;
}

.line-height-150 {
  line-height: 150%;
}
