@use 'variables';
@use 'mixins';

shared-file-selector {
  .first-file-upload {
    border: 1px dashed variables.$color-turquoise !important;
    transition: 0.3s ease all;

    &:focus {
      background: variables.$color-gray-4 !important;
    }

    &:hover {
      background: variables.$color-gray-5 !important;
    }
  }

  .delete-icon-btn {
    svg {
      fill: variables.$color-turquoise !important;
    }

    &:hover {
      background: variables.$color-gray-5 !important;
    }
  }

  .upload-hint .highlighted {
    color: variables.$color-turquoise !important;
  }
}

shared-file-selector.ng-invalid.ng-touched {
  .first-file-upload {
    border: 1px dashed variables.$color-red !important;
    background: variables.$background-form-input-error !important;
  }
}

.ng-submitted {
  shared-file-selector.ng-invalid {
    .first-file-upload {
      border: 1px dashed variables.$color-red !important;
      background: variables.$background-form-input-error !important;
    }
  }
}
