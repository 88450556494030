@use 'variables';
@use 'mixins';

shared-slider {
  .handle-labels {
    position: relative;
    height: 1rem;
    @include mixins.formInputFont();
    line-height: 1rem;
    margin-bottom: 0.5rem;

    span {
      position: absolute;
      margin-left: -0.75rem;
      width: 1.5rem;
      text-align: center;
    }
  }

  .interval-markers {
    position: relative;
    width: 100%;
    height: 0.3rem;

    span {
      position: absolute;
      height: 0.3rem;
      width: 0.1rem;
      background: variables.$color-gray-4;
    }
  }

  .p-slider {
    margin: auto;
    height: 0.1rem;
    position: relative;
    background: variables.$color-gray-4;

    .p-slider-range {
      background: variables.$color-turquoise;
    }

    .p-slider-handle {
      height: 1rem;
      width: 1rem;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
      border-radius: 0.3rem;
      background: variables.$color-turquoise;
    }
  }
}
