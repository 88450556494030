@use 'variables';
@use 'mixins';

.p-toast {
  width: fit-content;

  @include mixins.lt-md {
    width: 100vw;
  }
}

.p-toast-top-center {
  min-height: 45px !important;
  top: 0;
}

.p-toast .p-toast-message {
  max-height: 100%;
  min-height: 45px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.p-toast .p-toast-message .p-toast-message-content {
  white-space: nowrap;
  width: 100%;
  max-width: 100vw;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.p-toast .toast-message,
.toast-message-text {
  column-gap: 1rem;
}

.p-toast .p-toast-message.p-toast-message-success {
  border: none;
  background-color: variables.$color-green;
  color: #ffffff;

  .p-toast-icon-close {
    color: #ffffff;
    width: auto;
    height: auto;
    margin-left: 20px !important;
  }
}

.p-toast .p-toast-message.p-toast-message-error {
  border: none;
  background-color: variables.$color-red-accent;
  color: #ffffff;

  .p-toast-icon-close {
    color: #ffffff;
    width: auto;
    height: auto;
    margin-left: 20px !important;
  }
}

.p-toast .p-toast-message.p-toast-message-warn {
  border: none;
  background-color: variables.$color-orange-light;
  color: #ffffff;

  .p-toast-icon-close {
    color: #ffffff;
    width: auto;
    height: auto;
    margin-left: 20px !important;
  }
}

.p-toast .p-toast-message.p-toast-message-info {
  border: none;
  background-color: variables.$color-gray-1;
  color: #ffffff;

  .p-toast-icon-close {
    color: #ffffff;
    width: auto;
    height: auto;
    margin-left: 20px !important;
  }
}

.p-button.p-button-link {
  color: #ffffff !important;
  text-decoration: underline;
}
