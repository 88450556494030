@use 'variables';
@use 'mixins';

shared-input-term {
  @include mixins.formInput();
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5rem)) 1rem;
  width: 100%;
  height: variables.$form-input-height;
  min-height: variables.$form-input-height;
  overflow: hidden;
  padding: variables.$form-input-padding-vertical variables.$form-input-padding-horizontal;
  align-content: center;

  &:has(:focus) {
    @include mixins.formInput_focus();
  }

  &.ng-touched.ng-invalid {
    @include mixins.formInput_error();
  }

  .disabled-icon {
    fill: variables.$color-gray-3;
  }

  .input-term-field {
    width: 100%;
    display: block;
    overflow: hidden;

    input {
      @include mixins.formInputFont();
    }
  }

  input:disabled {
    color: variables.$color-gray-3;
  }
}
