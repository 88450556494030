@use 'variables';

@mixin regularFont {
  font-family: variables.$font-family-raleway;
  color: variables.$color-primary-text;
  font-size: 1rem;
  line-height: 120%;
}

@mixin formInput {
  @include formInputFont();
  @include formInputBorder();
  background: variables.$background-form-input;
}

@mixin formInputFont {
  color: variables.$color-primary-text;
  font-size: variables.$font-size-20;
  font-family: variables.$font-family-raleway;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: -0.04rem;
  font-variant-numeric: lining-nums proportional-nums;
}

@mixin formInput_focus {
  border: 1px solid var(--primary) !important;
  background: var(--color-gray-6) !important;
}

@mixin formInput_error {
  border: variables.$border-form-input-error;
  background: variables.$background-form-input-error;
}

@mixin formInputLabel {
  color: variables.$color-primary-text;
  font-size: variables.$font-size-10;
  font-family: variables.$font-family-raleway;
  font-weight: 700;
  line-height: 0.813rem;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}

@mixin formInputPlaceholder {
  color: variables.$color-gray-2;
  font-size: variables.$font-size-20;
  font-family: variables.$font-family-raleway;
  font-weight: 400;
  line-height: variables.$font-size-20;
  letter-spacing: -0.04rem;
  font-variant-numeric: lining-nums proportional-nums;
}

@mixin formInputValidationMessage {
  padding-left: 0;
  @include formInputLabel();
  color: variables.$color-red;
}

@mixin formInputBorder {
  border-radius: variables.$form-input-border-radius;
  border: variables.$border-form-input;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// MEDIA DEVICES
@mixin xs {
  @media screen and (max-width: #{variables.$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{variables.$screen-sm-min}) and (max-width: #{variables.$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{variables.$screen-md-min}) and (max-width: #{variables.$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{variables.$screen-lg-min}) and (max-width: #{variables.$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{variables.$screen-xl-min}) and (max-width: #{variables.$screen-xl-max}) {
    @content;
  }
}

// MEDIA LT QUERIES
@mixin lt-sm {
  @media screen and (max-width: #{variables.$screen-xs-max}) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: #{variables.$screen-sm-max}) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: #{variables.$screen-md-max}) {
    @content;
  }
}

@mixin lt-xl {
  @media screen and (max-width: #{variables.$screen-lg-max}) {
    @content;
  }
}

// MEDIA GT QUERIES
@mixin gt-xs {
  @media screen and (min-width: #{variables.$screen-sm-min}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{variables.$screen-md-min}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{variables.$screen-lg-min}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{variables.$screen-xl-min}) {
    @content;
  }
}
