@use 'variables';

.p-tooltip {
  display: block;

  .p-tooltip-arrow {
    display: none;
  }

  .p-tooltip-text {
    background: variables.$background-color;
    color: variables.$color-primary-text;
    max-width: 500px;
    text-align: center;
    padding: 1rem 1.5rem;
    border: variables.$border variables.$color-gray-4;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3);
  }
}
