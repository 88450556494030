@use 'variables';
@use 'mixins';

shared-modal-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.4);

  @media (min-width: variables.$responsive-breakpoint-tablet) {
    align-items: center;
  }

  > .shared-modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height: 0;
    border-radius: 1rem 1rem 0 0;
    width: 100%;

    .shared-modal-header {
      display: grid;
      grid-template-columns: 2.5rem 1fr 2.5rem;
      justify-items: center;
      width: 100%;
      padding: 1.5rem;
      border-radius: 1rem 1rem 0 0;

      @media (max-width: variables.$responsive-breakpoint-mobile) {
        position: sticky;
        top: 0;

        h2 {
          font-size: variables.$font-size-20;
        }
      }

      .close-icon {
        fill: white;
        cursor: pointer;

        @media (max-width: variables.$responsive-breakpoint-mobile) {
          height: 0.75rem;
          width: 0.75rem;
        }
      }
    }

    .shared-modal-body {
      max-width: 100vw;
      background: variables.$background-color;
      border-radius: 0 0 1rem 1rem;
      overflow-y: auto;
      overflow-x: clip;
      display: flex;

      @media (max-width: variables.$responsive-breakpoint-mobile) {
        max-height: calc(100vh - 4.5rem);
        justify-content: end;
        border-radius: 0;
      }
    }

    @media (max-width: variables.$responsive-breakpoint-mobile) {
      justify-content: end;
      max-width: 100%;
    }
  }
}
