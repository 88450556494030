@use 'variables';

shared-radio-button {
  display: flex;
  gap: 0.75rem;
  align-items: center;

  label {
    font-size: 0.875rem;
    line-height: 1.3;
    cursor: pointer;
    font-weight: 800;
  }
}
