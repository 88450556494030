// BREAKPOINTS
$responsive-breakpoint-desktop: 960px;
$responsive-breakpoint-tablet: 800px;
$responsive-breakpoint-mobile: 600px;

$page-max-width: 75rem;

// FONTS
$font-family-raleway: 'Raleway', sans-serif;

// FONTS SIZE
$font-size-8: 0.5rem;
$font-size-10: 0.625rem;
$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-26: 1.625rem;
$font-size-28: 1.75rem;
$font-size-30: 1.875rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;

// MAIN COLORS
$color-primary-text: var(--dark);
$color-secondary-text: var(--color-secondary-text);
$color-gray-1: var(--color-gray-1);
$color-gray-2: var(--color-gray-2);
$color-gray-3: var(--color-gray-3);
$color-gray-4: var(--color-gray-4);
$color-gray-5: var(--color-gray-5);
$color-gray-6: var(--color-gray-6);
$color-gray-turquoise: var(--color-gray-turquoise);
$color-turquoise: #{var(--color-turquoise)};
$color-turquoise-light: var(--color-turquoise-light);
$color-orange: var(--color-orange);
$color-orange-light: var(--color-orange-light);
$color-orange-dark: var(--color-orange-dark);
$color-red: var(--color-red);
$color-red-accent: var(--color-red-accent);
$color-red-accent-100: var(--color-red-accent-100);
$color-green: var(--color-green);
$color-green-light: var(--color-green-light);
$color-green-2: var(--color-green-2);
$color-primary: var(--primary);
$color-primary-contrast: var(--primary-contrast);
$color-light: var(--light);

$border-width: 1px;
$border: $border-width solid #b9c8d366;
$border-form-input: $border-width solid $color-gray-turquoise;
$border-form-input-focus: $border-width solid $color-turquoise;
$border-form-input-error: $border-width solid $color-red;

$background-color: $color-gray-5;
$background-blue-gradient: var(--background-primary-gradient);
$background-blue-gradient-lighten: var(--background-primary-gradient-lighten);
$background-container-active: var(--color-turquoise-light);
$background-form-input: var(--light);
$background-form-input-focus: var(--light);
$background-form-input-error: var(--light);

//FORM
$form-input-padding-horizontal: 1.5rem;
$form-input-padding-vertical: 0.75rem;
$form-input-line-height: 1.75rem;
$form-input-height: 3.438rem;
$form-input-border-radius: 1.375rem;

// MEDIA BREAKPOINTS
$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-lg-min: 1280px;
$screen-xl-min: 1920px;

$screen-xs-max: 599px;
$screen-sm-max: 959px;
$screen-md-max: 1279px;
$screen-lg-max: 1919px;
$screen-xl-max: 5000px;

// DROPDOWN
$dropdown-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.3);
