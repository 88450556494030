@use 'variables';
@use 'mixins';

p-dropdownitem .property-mortgage-item {
  &.selected {
    background-color: variables.$color-gray-5;
  }
}

shared-property-mortgage-select .inline-list .item {
  border: 2px solid white;

  &.selected {
    border-color: var(--primary);
  }
}
