.p-component-overlay {
  width: 100dvw;
  height: 100dvh;
  background: rgb(0 0 0 / 30%);
}

.p-dialog:not(.p-confirmdialog) {
  max-height: 100% !important;

  .p-dialog-content {
    height: calc(100vh);
    width: calc(100vw);
  }
}

.p-overlay-mask {
  background-color: rgba(0, 0, 0, 0.4);
}
