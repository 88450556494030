@use 'variables';
@use 'mixins';

shared-button-with-image {
  .headline {
    @include mixins.regularFont();
    font-size: variables.$font-size-20;

    @media (max-width: variables.$responsive-breakpoint-mobile) {
      font-size: variables.$font-size-20;
    }
  }

  .description {
    @include mixins.regularFont();
    font-size: variables.$font-size-14;
  }

  .selected {
    border-color: variables.$color-turquoise;
    background-color: var(--color-turquoise-50);
  }
}
