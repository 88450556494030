@use 'variables';
@use 'mixins';

p-carousel {
  width: 100%;
  overflow: hidden;

  // WARNING
  &.warning {
    .p-carousel-indicators {
      .p-carousel-indicator {
        &.p-highlight {
          background-color: var(--color-orange-500);
        }
      }
    }

    .p-carousel-prev,
    .p-carousel-next {
      color: var(--color-orange-500);
    }
  }

  &.single-page {
    .p-carousel-indicators,
    .p-carousel-prev,
    .p-carousel-next {
      display: none;
    }
  }

  &.no-indicators {
    .p-carousel-indicators {
      display: none;
    }
  }

  &.overflow-visible {
    .p-carousel-content,
    .p-carousel-items-content {
      overflow: visible;
    }
  }
}

.p-carousel-indicator {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--color-dark-100);

  &.p-highlight {
    background-color: var(--primary);
  }

  button {
    width: 100%;
    height: 100%;
  }
}

.p-carousel-container {
  gap: 1.25rem;
}

.p-carousel-indicators {
  gap: 0.5rem;
  padding-top: 1rem;
}

.p-carousel-prev,
.p-carousel-next {
  padding: 0.65rem;
  background: var(--light);
  border: 1px solid var(--primary);
  box-shadow: 0px 0px 8px -2px var(--dark);
  border-radius: 10px;
  z-index: 2;

  @include mixins.lt-md {
    display: none;
  }
}
