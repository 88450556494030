@use 'variables';

@layer primeng, primeng-override;

@layer primeng-override {
  button.p-button {
    min-width: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    border: none;
    border-radius: 1rem;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
    transition: 0.3s ease background-color;
    color: variables.$color-primary-text;
    background-color: var(--color-dark-50);

    &:hover {
      background: rgba(var(--color-dark-rgb), 0.2);
    }

    &.p-button-primary {
      &:not(.p-button-outlined) {
        background: variables.$color-primary;
        color: white;
        fill: white;

        &:focus,
        &:hover {
          background: var(--color-primary-700);
        }
      }

      &.p-button-outlined {
        background: transparent;
        color: variables.$color-primary-text;
        fill: variables.$color-primary-text;
        border: 1px solid variables.$color-primary;

        &.color-light {
          color: var(--light);
          fill: var(--light);
        }

        &:focus,
        &:hover {
          background: rgba(var(--color-primary-rgb), 0.4);
        }
      }
    }

    &.p-button-grey {
      background: rgba(var(--color-dark-rgb), 0.5);
      color: white;
      fill: white;

      &:focus,
      &:hover {
        background: var(--color-dark-100);
      }

      &.p-button-outlined {
        background: transparent;
        color: variables.$color-gray-1;
        fill: variables.$color-gray-1;
        border: 1px solid variables.$color-gray-1;

        &:focus,
        &:hover {
          background: variables.$color-gray-6;
        }
      }
    }

    &.p-button-warn {
      background: variables.$color-orange;
      color: white;
      fill: white;

      &:focus,
      &:hover {
        background: var(--color-orange-400);
      }

      &.p-button-outlined {
        background: transparent;
        color: variables.$color-orange;
        fill: variables.$color-orange;
        border: 1px solid variables.$color-orange;

        &:focus,
        &:hover {
          background: rgba(var(--color-orange-rgb), 0.4);
        }
      }
    }

    &.p-button-danger {
      &:not(.p-button-outlined) {
        background: var(--color-red-300);
        color: white;
        fill: white;

        &:focus,
        &:hover {
          background: rgba(var(--color-red-rgb), 0.6);
        }
      }

      &.p-button-outlined {
        background: transparent;
        color: variables.$color-red-accent;
        fill: variables.$color-red-accent;
        border: 1px solid variables.$color-red-accent;

        &:focus,
        &:hover {
          background: rgba(var(--color-red-rgb), 0.6);
        }
      }
    }

    &.p-button-light-grey {
      background: variables.$color-gray-4;
      color: variables.$color-gray-3;
      fill: variables.$color-gray-3;

      &:focus,
      &:hover {
        background: variables.$color-gray-5;
      }
      &.p-button-outlined {
        background: transparent;
        color: variables.$color-gray-5;
        fill: variables.$color-gray-5;
        border: 1px solid variables.$color-gray-5;

        &:focus,
        &:hover {
          background: variables.$color-gray-6;
          color: variables.$color-primary-text;
        }
      }
    }

    &.p-button-light {
      background: white;
      color: variables.$color-turquoise;
      fill: variables.$color-turquoise;

      &:focus,
      &:hover {
        background: variables.$color-gray-5;
      }
    }

    &.p-button-company-color {
      background: var(--company-color);
      color: white;
      fill: white;

      &:focus,
      &:hover {
        background: var(--company-color8);
      }
    }

    &:not(.icon-only) {
      justify-content: start;
    }

    &.icon-only {
      padding: 1rem;
      justify-content: center;
      width: min-content;

      shared-icon {
        margin: auto;
      }

      &.small {
        border-radius: 40%;
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    &.centered {
      justify-content: center;
    }

    &:disabled {
      opacity: 0.2;
    }

    &.small {
      padding: 0px;
      height: fit-content;
    }

    &.medium {
      padding: 0.65rem;
      height: fit-content;
    }

    .p-button-loading-icon {
      margin-right: 0.5rem;
    }
  }
}
