@use 'variables';
@use 'mixins';

shared-input-text input {
  width: 100%;
  height: variables.$form-input-height;
  padding: variables.$form-input-padding-vertical variables.$form-input-padding-horizontal;
  transition: 0.3s ease all;
  @include mixins.formInput();
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::placeholder {
    @include mixins.formInputPlaceholder();
  }

  &:focus {
    @include mixins.formInput_focus();
  }

  &.ng-invalid.ng-touched {
    @include mixins.formInput_error();
  }

  &:disabled {
    color: variables.$color-gray-3;
  }
}
