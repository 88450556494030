@use 'variables';

shared-labeled-value {
  .value {
    font-family: variables.$font-family-raleway;
    color: variables.$color-primary-text;

    label {
      font-family: variables.$font-family-raleway;
      font-size: 0.625rem;
      line-height: 110%;
      letter-spacing: 0.0625rem;
      font-weight: 700;
      text-transform: uppercase;
      align-self: end;
    }

    span {
      font-weight: 400;
      line-height: 110%;
    }

    @media (max-width: variables.$responsive-breakpoint-tablet) {
      align-self: center;
    }
  }

  .image {
    display: block;
    margin: auto;
    max-height: 4rem;
    max-width: 5rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
