@use 'variables';
@use 'mixins';

p-inputnumber.input-number {
  display: flex;
  height: variables.$form-input-height;
  width: 100%;
  padding: variables.$form-input-padding-vertical variables.$form-input-padding-horizontal;
  transition: 0.3s ease all;
  @include mixins.formInput();
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  input {
    @include mixins.formInputFont();

    &::placeholder {
      @include mixins.formInputPlaceholder();
    }
  }

  &:has(:focus) {
    @include mixins.formInput_focus();
  }

  &:has(:disabled) {
    ::placeholder,
    input {
      color: variables.$color-gray-3;
    }
  }

  &.ng-invalid.ng-touched {
    @include mixins.formInput_error();
  }
}
