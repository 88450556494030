.transition-all-4 {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.fade-in-anim {
  animation: fadeIn 0.4s forwards;
}

.r-slide-in-anim {
  animation: rightSlideIn 0.4s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rightSlideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// COMPONENT SPECIFIC ANIMATIONS
// chaining animations can cause lost of component id prefix for keyframe animation name
// to prevent it, we put animation here (ROOT)
// animation becomes accessible for any component
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------------------------
// /platform/properties/components/property-sub-card-list/components/mortgage-item/mortgage-item.component.scss
// -------------------------------------------------------------------------------------------------------------------

@keyframes infoBannerInAnim {
  0% {
    transform: translateY(-100%);
  }
  30% {
    transform: translateY(0%);
  }
  60% {
    transform: translateY(-15%);
  }
  80% {
    transform: translateY(0%);
  }
  90% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(1px);
  }
}

@keyframes infoBannerOutAnim {
  0% {
    transform: translateX(0);
  }
  20% {
    border-radius: 2rem;
  }
  99% {
    height: 0;
    opacity: 0;
    padding: 0;
    top: 70%;
    width: 0;
    right: 1rem;
  }
  100% {
    height: 0;
    opacity: 0;
    padding: 0;
    top: 70%;
    width: 0;
    right: 1rem;
    display: none;
  }
}

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
