.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.block {
  display: block;
}

.d-none {
  display: none;
}

.layout-h-c-f {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr auto;
}

.layout-fr {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}

.layout-auto-fr {
  display: grid;
  grid-template-columns: auto 1fr;
}

.layout-fr-auto {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
}

.layout-auto-fr-auto {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.layout-fr-fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.layout-fr-fr-fr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.layout-fr-fr-fr-fr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.layout-rows-auto-fr {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
}

.span-2 {
  grid-column: span 2;
}

.span-3 {
  grid-column: span 3;
}

.align-self-start {
  align-self: start;
}

.align-self-stretch {
  align-self: stretch;
}

.align-self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.span-column-2 {
  grid-column: span 2;
}

.span-row-2 {
  grid-row: span 2;
}

.align-items-start {
  align-items: start !important;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 2rem;
}

.flex-grow-1 {
  flex-grow: 1;
}
