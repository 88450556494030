@use 'variables';
@use 'mixins';

p-datepicker {
  display: flex;
  width: 100%;
  height: fit-content;
  transition: 0.3s ease all;
  @include mixins.formInput();

  &:has(:focus) {
    @include mixins.formInput_focus();
  }

  .p-inputwrapper {
    width: 100%;

    input {
      @include mixins.formInputFont();
      width: 100%;
      height: calc(#{variables.$form-input-height} - (2 * #{variables.$border-width}));
      padding: variables.$form-input-padding-vertical variables.$form-input-padding-horizontal;

      &:disabled {
        color: variables.$color-gray-3;
      }
    }

    button,
    button:hover,
    button:focus {
      background-color: transparent;
      padding: 1rem 1.5rem;
    }
  }

  &:focus {
    @include mixins.formInput_focus();
  }

  &.ng-invalid.ng-touched {
    @include mixins.formInput_error();
  }
}

.p-datepicker-panel {
  background: #f9fbfc;
  padding: 1rem;
  border-radius: variables.$form-input-border-radius;
  box-shadow: variables.$dropdown-box-shadow;
  margin: 0.25rem 0 !important;

  .p-datepicker-weekday {
    font-family: variables.$font-family-raleway;
  }

  .p-datepicker-day,
  .p-datepicker-month,
  .p-datepicker-year {
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    @include mixins.formInputFont();

    &.p-datepicker-day-selected {
      color: variables.$color-primary-contrast;
      font-weight: 700;
      background-color: rgba(var(--color-primary-rgb), 0.9);
      border-radius: 1rem;
    }

    &.p-disabled {
      color: variables.$color-gray-3;
    }
  }

  .p-datepicker-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    button {
      font-family: variables.$font-family-raleway;
      border: none;
      background: none;
      font-size: 1rem;
      margin: 0 0.25rem;
      color: variables.$color-primary;
      font-weight: 800;
    }
  }
}
