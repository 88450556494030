// WIDTH
.w-45 {
  width: 45%;
}

.w-60 {
  width: 60%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.w-a {
  width: auto;
}

.max-w-100 {
  max-width: 100%;
}

// HEIGHT
.h-100 {
  height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.min-h-100-dvh {
  min-height: 100dvh;
}

.h-fit {
  height: fit-content;
}

// IMAGE
.object-fit-contain {
  object-fit: contain;
}
