@use 'variables';

.p-confirmdialog {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  width: 25rem;
  margin: 0 1rem;
  max-height: 20rem !important;

  .p-dialog-header {
    padding: 2rem 3rem 0rem 3rem;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: variables.$font-family-raleway;

    .p-dialog-close-button {
      padding: 0;
      background: none;
      border: none;
      min-width: unset;
      min-height: unset;
    }
  }

  .p-dialog-content {
    padding: 2rem 3rem;
    font-family: variables.$font-family-raleway;
    color: variables.$color-primary-text;
    font-size: variables.$font-size-14;
    line-height: 1.1rem;
    height: unset;
    width: unset;
  }

  .p-dialog-footer {
    display: flex;
    gap: 1rem;
    padding: 0 3rem 2rem;

    button {
      font-family: variables.$font-family-raleway;

      .p-icon-wrapper {
        display: none;
      }

      &.p-confirm-dialog-reject {
        width: max-content;
        justify-content: center;
      }

      &.p-confirmdialog-accept-button {
        justify-content: start;
        text-align: left;
        width: 100%;
      }
    }
  }
}
