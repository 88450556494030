@use 'variables';
@use 'mixins';

shared-address-form {
  display: flex;
  flex-direction: column;

  .address-input-switch {
    padding: 0.25rem 0.5rem;
    font-weight: 700;
    font-size: variables.$font-size-12;
    cursor: pointer;
    margin-left: auto;
  }

  .manual-form-container {
    background-color: variables.$color-gray-6;
    padding: 1rem;
    border-radius: 1.5rem;
    margin: 1rem 0;
    overflow: hidden;
    animation: manualFormAnim 0.2s forwards;
  }

  shared-property-address-select {
    animation: selectAddressAnim 0.2s forwards;
  }

  > label {
    width: 100%;
    @include mixins.formInputLabel();
  }
}

@keyframes manualFormAnim {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes selectAddressAnim {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
