@use 'variables';
@use 'mixins';

p-select {
  display: flex;
  transition: 0.3s ease all;
  @include mixins.formInput();
  min-height: variables.$form-input-height;
  padding: variables.$form-input-padding-vertical variables.$form-input-padding-horizontal;
  width: 100%;

  .p-select-label {
    align-content: center;
  }

  .p-select-dropdown {
    align-content: center;
    margin-left: 0.5rem;
  }

  &.p-inputwrapper-focus {
    @include mixins.formInput_focus();
  }

  &.ng-invalid.ng-touched {
    @include mixins.formInput_error();
  }

  .p-placeholder {
    span {
      @include mixins.formInputPlaceholder();
    }
  }

  &.p-disabled {
    color: variables.$color-gray-3;
  }

  .value {
    @include mixins.formInputFont();
  }

  .p-select-dropdown-icon shared-icon {
    fill: variables.$color-primary;
  }

  shared-icon {
    margin: 0 0.5rem 0 auto;
    fill: variables.$color-gray-3;
  }

  .p-iconwrapper {
    position: relative;
    margin-right: 1.5rem;
    margin-left: auto;
    margin-top: 0;
    padding-left: 0.5rem;
  }
}

.p-select-overlay {
  width: 100%;

  margin: 0.25rem 0;
  box-shadow: variables.$dropdown-box-shadow;
  background: white;
  overflow: hidden;
  border-radius: variables.$form-input-border-radius;
  max-width: calc(100vw - 2rem);
  width: 100%;

  p-selectitem {
    display: flex;

    &:not(:last-child) {
      border-bottom: variables.$border;
    }

    &:hover {
      background: variables.$color-gray-5;
    }

    .p-select-option {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 3rem;

      > span {
        display: block;
        padding: 0.75rem 1rem;
        font-size: variables.$font-size-14;
        font-family: variables.$font-family-raleway;
        font-variant-numeric: lining-nums proportional-nums;
        font-weight: 400;
        letter-spacing: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .p-select-empty-message {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 3rem;
    padding: 0.75rem 1rem;
    font-size: variables.$font-size-14;
    font-family: variables.$font-family-raleway;
    font-variant-numeric: lining-nums proportional-nums;
    font-weight: 400;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-select-header {
    padding: 0.75rem 0.75rem 0.25rem 0.75rem;
    border-top-right-radius: 1.25rem;
    border-top-left-radius: 1.25rem;

    .p-iconfield {
      width: 100%;
      display: flex;
      align-items: center;
      height: 3rem;
      @include mixins.formInputBorder();

      .p-select-filter {
        padding: 0 1rem;
      }

      .p-inputicon {
        position: relative;
        top: 0;
        margin-right: 1rem;
      }

      .p-select-filter-icon {
        position: unset;
      }
    }
  }
}
