@use 'variables';
@use 'mixins';

$input-height: variables.$form-input-height;

shared-form-field-wrapper {
  & > div {
    width: 100%;
    display: grid;
    grid-gap: 0.5rem;
  }

  .rows {
    grid-template-columns: 100%;
    grid-template-rows: 1rem minmax($input-height, min-content) min-content;
    align-items: center;
  }

  &:has(shared-file-selector),
  &:has(ap-simple-html-editor) {
    grid-template-rows: 1rem auto min-content;
  }

  .form-field-label {
    width: 100%;
    @include mixins.formInputLabel();
  }

  .validation-messages shared-validation-message {
    @include mixins.formInputValidationMessage();
  }
}
