@use 'variables';

p-tag {
  display: grid;
  justify-self: start;
  align-self: center;

  shared-icon {
    margin-right: 0.25rem;
  }

  .p-tag {
    border-radius: 0.375rem;
    background: variables.$color-gray-3;
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    padding: 0.25rem 0.4rem;

    &.p-tag-warning {
      background: var(--color-orange);
    }
  }
}
