@use 'variables';
@use 'mixins';

shared-property-address-select {
  position: relative;
  width: 100%;
  display: block;
  font-size: 14px;

  &.ng-submitted.ng-invalid {
    input {
      background-color: rgba(var(--color-orange-rgb), 0.2);
    }
  }

  .pi-spinner {
    height: 30px;
    width: 30px;
    display: block;
    position: absolute;
    border-radius: 50%;
    right: 1rem;
    top: 0.875rem;
    animation: rotation 2s infinite linear;
    background-image: url('../../../../../../apps/client-portal/src/assets/icons/spinner.svg');
  }

  .spinner {
    top: 10px;
    position: absolute;
    height: 30px;
    right: 10px;
  }

  input {
    @include mixins.formInput();

    &.warn {
      background-color: rgba(var(--color-orange-rgb), 0.2);
    }
  }
}

.found-addresses {
  position: absolute;
  max-height: 224px;
  top: 51px;
  left: 0;
  z-index: 2;
  overflow: auto;
  border: 1px solid #edeff0;
  width: 100%;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(138, 143, 149, 0.2);

  .found-address {
    display: flex;
    height: 2rem;
    border-bottom: 1px solid #edeff0;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    transition: all ease 0.2s;

    .address-post-code {
      margin-right: 0.5rem;
    }

    .address-street {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .address-count {
      margin-left: auto;
      margin-right: 0;
      color: variables.$color-gray-3;
      transition: color ease 0.2s;
    }

    &:hover {
      background: var(--color-primary-100);
      cursor: pointer;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
