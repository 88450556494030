@use 'variables';

.lenders-container {
  .lender-container {
    .lender-label {
      text-align: center;
      font-size: variables.$font-size-14;
      font-weight: 800;
    }

    .lender-button {
      border: 2px solid variables.$color-light;
      transition: 0.3s ease all;

      &:hover {
        border-color: variables.$color-primary;
        cursor: pointer;
      }

      &.selected {
        border-color: variables.$color-primary;
        background-color: var(--color-primary-50);
      }
    }
  }

  .lender-others-select-container {
  }
}

.p-dropdown-item .lender-other-select-item {
  padding: 0.5rem 1rem;
}
