@use 'variables';
@use 'mixins';

shared-select-button {
  .p-selectbutton {
    display: flex;
    width: 100%;
    height: variables.$form-input-height;
    gap: 0.5rem;

    p-togglebutton {
      flex-grow: 1;

      .p-togglebutton {
        height: 100%;
        width: 100%;
        padding: 0 0.5rem;
        @include mixins.formInputFont();
        @include mixins.formInputBorder();
        transition: 0.3s ease-in-out border;
        background-color: var(--light);

        &.p-togglebutton-checked {
          @include mixins.formInput_focus();
          background: var(--color-primary-50) !important;
        }
      }
    }
  }

  &.ng-invalid.ng-touched .p-togglebutton {
    @include mixins.formInput_error();
  }
}
