:root {
  --primary: #e0c18d;
  --color-primary-50: rgb(251, 248, 241);
  --color-primary-100: rgb(246, 236, 221);
  --color-primary-200: rgb(240, 224, 198);
  --color-primary-300: rgb(233, 212, 175);
  --color-primary-400: rgb(229, 202, 158);
  --color-primary-500: rgb(224, 193, 141);
  --color-primary-600: rgb(220, 187, 133);
  --color-primary-700: rgb(216, 179, 122);
  --color-primary-800: rgb(211, 171, 112);
  --color-primary-900: rgb(203, 158, 93);
  --color-primary-rgb: 224, 193, 141;

  --primary-light: #8de2f6;
  --primary-contrast: #fff;

  --background-primary-gradient: linear-gradient(300deg, #8de2f6, #3ccae6);
  --background-green-gradient: linear-gradient(#96d4c5 50%, #49d1ab);
  --background-primary-gradient-lighten: linear-gradient(300deg, #a0e6f6, #4bd4f0);
  --color-gray-turquoise: #bdd5da;
  --color-light-gray-turquoise: #deeaec;
  --color-dark-gray-turquoise: #6bc6d3;

  --dark: #5f6176;
  --color-dark-50: rgb(236, 236, 239);
  --color-dark-100: rgb(207, 208, 214);
  --color-dark-200: rgb(175, 176, 187);
  --color-dark-300: rgb(143, 144, 159);
  --color-dark-400: rgb(119, 121, 139);
  --color-dark-500: rgb(95, 97, 118);
  --color-dark-600: rgb(87, 89, 110);
  --color-dark-700: rgb(77, 79, 99);
  --color-dark-800: rgb(67, 69, 89);
  --color-dark-900: rgb(50, 51, 70);
  --color-dark-rgb: 95, 97, 118;

  --light: #fff;
  --color-light-rgb: 255, 255, 255;

  --color-turquoise: #3bc9e6;
  --color-turquoise-50: rgb(225, 250, 255);
  --color-turquoise-100: rgb(196, 239, 248);
  --color-turquoise-200: rgb(157, 228, 243);
  --color-turquoise-300: rgb(118, 217, 238);
  --color-turquoise-400: rgb(88, 209, 234);
  --color-turquoise-500: rgb(59, 201, 230);
  --color-turquoise-600: rgb(53, 195, 227);
  --color-turquoise-700: rgb(45, 188, 223);
  --color-turquoise-800: rgb(38, 181, 219);
  --color-turquoise-900: rgb(25, 169, 213);
  --color-turquoise-rgb: 59, 201, 230;

  --color-blue: #5aa4f1;

  --color-orange: #ff835c;
  --color-orange-50: rgb(253, 239, 234);
  --color-orange-100: rgb(249, 215, 204);
  --color-orange-200: rgb(245, 189, 170);
  --color-orange-300: rgb(241, 162, 135);
  --color-orange-400: rgb(238, 142, 110);
  --color-orange-500: rgb(235, 122, 84);
  --color-orange-600: rgb(233, 114, 77);
  --color-orange-700: rgb(229, 103, 67);
  --color-orange-800: rgb(226, 93, 58);
  --color-orange-900: rgb(221, 74, 41);
  --color-orange-rgb: 235, 122, 84;

  --color-red: #ff2d2d;
  --color-red-50: rgb(255, 230, 230);
  --color-red-100: rgb(255, 192, 192);
  --color-red-200: rgb(255, 150, 150);
  --color-red-300: rgb(255, 108, 108);
  --color-red-400: rgb(255, 77, 77);
  --color-red-500: rgb(255, 45, 45);
  --color-red-600: rgb(255, 40, 40);
  --color-red-700: rgb(255, 34, 34);
  --color-red-800: rgb(255, 28, 28);
  --color-red-900: rgb(255, 17, 17);
  --color-red-rgb: 255, 45, 45;

  --color-green: #38bf99;
  --color-green-50: rgb(231, 247, 243);
  --color-green-100: rgb(195, 236, 224);
  --color-green-200: rgb(156, 223, 204);
  --color-green-300: rgb(116, 210, 184);
  --color-green-400: rgb(86, 201, 168);
  --color-green-500: rgb(56, 191, 153);
  --color-green-600: rgb(50, 185, 145);
  --color-green-700: rgb(43, 177, 134);
  --color-green-800: rgb(36, 169, 124);
  --color-green-900: rgb(23, 155, 107);
  --color-green-rgb: 56, 191, 153;

  --color-purple: #ac68f0;
  --color-purple-50: rgb(245, 237, 253);
  --color-purple-100: rgb(230, 210, 251);
  --color-purple-200: rgb(214, 180, 248);
  --color-purple-300: rgb(197, 149, 245);
  --color-purple-400: rgb(184, 127, 242);
  --color-purple-500: rgb(172, 104, 240);
  --color-purple-600: rgb(165, 96, 238);
  --color-purple-700: rgb(155, 85, 236);
  --color-purple-800: rgb(146, 75, 233);
  --color-purple-900: rgb(130, 58, 229);
  --color-purple-rgb: 172, 104, 240;

  --color-gray-1: #a8acb2;
  --color-gray-2: #b8bbc3;
  --color-gray-3: #b9c8d3;
  --color-gray-4: #e3eaef;
  --color-gray-5: #f4f6f8;
  --color-gray-5-rgb: 244, 246, 248;
  --color-gray-6: #fafafa;

  --color-turquoise-light: #7ee9ff;
  --color-secondary-text: #9fa4b0;
  --color-orange-dark: #ff693b;
  --color-red-dark-rgb: 192, 48, 48;
  --orange-contrast: #fff;
  --color-orange-light: #f9b575;
  --color-red-accent: #ff8181;
  --color-red-accent-100: #ffb6b6;
  --color-green-light: #69dbac;
  --color-green-2: #39d3ae;
  --green-contrast: #fff;
  --app-background: #f3f4f6;
  --app-background-rgb: 243, 244, 246;
}
